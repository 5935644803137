<template>
  <div class="orderTest-container">setPriceAdmin</div>
</template>
<script>
  export default {
    name: 'SetPriceAdmin',
    components: {},
    data() {
      return {}
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
  }
</script>
<style lang="scss" scoped></style>
